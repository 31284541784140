import React, { Component } from 'react'
import './ClearlyMyName.css'

export default class ClearlyMyName extends Component {
    render() {
        return (
            <div className='clearly-wrapper'>
                <h1>Jared Parker</h1>
                <h2>Software Engineer</h2>
            </div>
        )
    }
}
